<template>
  <v-container fluid class="pa-0">
    <app-layout image="BG-Empleos.svg" :is-menu-btn="true" :xs="300">
      <template #title>

        <div class="d-flex justify-center align-center flex-column "
             :class="$vuetify.breakpoint.width < 600 && $vuetify.breakpoint.width > 501 ? 'mt-10': ''">
          <v-chip color="white" class="pa-5 mb-4 rounded-pill" style="height: 3.5vw">
          <span
              class="textCyan--text text-caption text-sm-subtitle-1 text-md-subtitle-2 text-lg-h4 text-xl-h2 font-weight-bold">{{
              `${getCantidadPlazas} PLAZAS`
            }}</span>
          </v-chip>
          <p class="white--text text-caption text-sm-body-1 text-md-subtitle-1 text-lg-h6 text-xl-h4 font-weight-bold pa-0 ma-0 "
             :class="$vuetify.breakpoint.smAndDown ? '' : 'mt-5'">
            UNIDAD DE GESTIÓN DEL TALENTO HUMANO</p>
          <p class="white--text text-body-2 text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h2 font-weight-bold pa-0 ma-0">
            {{ detalleEmpleo?.nombre?.toUpperCase() }}</p>
          <p class="white--text text-caption text-sm-body-1 text-md-subtitle-1 text-lg-h6 text-xl-h4 font-weight-bold pa-0 ma-0">
            {{ detalleEmpleo?.institucion?.nombre.toUpperCase() }}</p>
        </div>

      </template>
      <template #body-content>
        <div class="px-5">
          <v-row class="justify-center mt-15">
            <v-col cols="12" md="6" class="d-flex justify-center align-center" style="gap: 20px">
              <!--           <div class="d-flex justify-center" style="width: 90%; position: absolute; top: -40px">-->
              <v-img
                  src="../assets/img/Desc-puesto.svg"
                  max-width="120"
              ></v-img>
              <!--           </div>-->
              <div class="d-flex flex-column">
                <p class="textCyan--text font-weight-bold text-h5 ma-0">Descripción </p>
                <p class="textCyan--text font-weight-bold text-h5 ma-0"> del puesto</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-card color="white rounded-xl mt-10" elevation="0" class="mx-10 pa-8">

                <div v-html="detalleEmpleo?.descripcion_del_puesto">

                </div>

              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="flex-row">
              <v-card color="cardBackground rounded-xl mt-10" class="mx-10 pa-8" elevation="0"
                      style="position: relative">
                <div class="d-flex justify-center" style="width: 90%; position: absolute; top: -40px">
                  <v-img
                      src="../assets/img/Func-realizar.svg"
                      max-width="80"
                      style="position: absolute"
                  ></v-img>
                </div>
                <p class="textCyan--text font-weight-bold text-h5 mt-5">Funciones a realizar</p>
                <div v-html="detalleEmpleo?.funciones"></div>
              </v-card>
              <v-card color="cardBackground rounded-xl mt-10" class="mx-10 pa-8" elevation="0"
                      style="position: relative">
                <div class="d-flex justify-center" style="width: 90%; position: absolute; top: -40px">
                  <v-img
                      src="../assets/img/Mod-aplicacion.svg"
                      max-width="80"
                      style="position: absolute"
                  ></v-img>
                </div>
                <p class="textCyan--text font-weight-bold text-h5 mt-5">Modalidad de aplicación</p>
                <div v-html="detalleEmpleo?.modalidad"></div>

              </v-card>
            </v-col>

            <v-col cols="12" md="6" class="order-2">
              <v-card color="cardBackground rounded-xl mt-10" class="mx-10 pa-8" elevation="0"
                      style="position: relative">
                <div class="d-flex justify-center" style="width: 90%; position: absolute; top: -40px">
                  <v-img
                      src="../assets/img/Req-indispensables.svg"
                      max-width="80"
                      style="position: absolute"
                  ></v-img>
                </div>
                <p class="textCyan--text font-weight-bold text-h5 mt-5">Requisitos indispensables</p>
                <div v-html="detalleEmpleo?.requisitos"></div>

              </v-card>
              <v-card color="cardBackground rounded-xl mt-10" class="mx-10 pa-8" elevation="0"
                      style="position: relative">
                <div class="d-flex justify-center" style="width: 90%; position: absolute; top: -40px">
                  <v-img
                      src="../assets/img/Exp-previa.svg"
                      max-width="80"
                      style="position: absolute"
                  ></v-img>
                </div>
                <p class="textCyan--text font-weight-bold text-h5 mt-5">Experiencia previa</p>
                <div v-html="detalleEmpleo?.experiencia"></div>

              </v-card>
              <v-card color="cardBackground rounded-xl mt-10" class="mx-10 pa-8" elevation="0"
                      style="position: relative">
                <div class="d-flex justify-center" style="width: 90%; position: absolute; top: -40px">
                  <v-img
                      src="../assets/img/Se-ofrece.svg"
                      max-width="80"
                      style="position: absolute"
                  ></v-img>
                </div>
                <p class="textCyan--text font-weight-bold text-h5 mt-5">Se ofrece</p>
                <div v-html="detalleEmpleo?.se_ofrece"></div>

              </v-card>
            </v-col>
          </v-row>


        </div>

        <v-row class="justify-center my-10">
          <v-col cols="2" class="d-flex justify-center">
            <v-btn class="white--text" color="textCyan" rounded @click="backPage">
              Regresar
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-layout>

  </v-container>

</template>
<script>
import AppLayout from "@/components/AppLayoutComponent.vue";

export default {
  components: {
    AppLayout
  },

  data() {
    return {
      detalleEmpleo: null
    }
  },
  methods: {
    async getEmpleo() {
      const id = this.$route.params.id
      const {data} = await this.services.MercadoLaboral.getEmpleo(id)
      this.detalleEmpleo = data
    },
    backPage() {

      this.$router.push({name: 'mercado-laboral', query: {page: this.$route.query.page}})
    }
  },
  computed: {
    getCantidadPlazas() {
      if(this.detalleEmpleo && this.detalleEmpleo.cantidad) {
        return this.detalleEmpleo.cantidad
      }

      return 0
    }
  },
  async created() {

    if (this.$route.params.id) {
      await this.getEmpleo()
      window.scrollTo({top: 0, left: 0});
    } else {
      this.$router.push({path: '/mercado-laboral'})
    }
  }
}
</script>
<style lang="scss" scope>
 .v-toolbar__image .v-responsive__content .header-img--title-style{
   margin-left: 100px;
   margin-right: 100px;
   text-align: center;
}
</style>
