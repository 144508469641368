<template>
  <v-container fluid class="pa-0">
    <app-layout image="BG-Empleos.svg" :is-menu-btn="true">
      <template #title>
        <v-card-title class="subtitle-2 text-sm-h6 text-md-h5 text-lg-h3 white--text mb-n2 mb-lg-4 font-weight-black text-center">
          Mercado laboral
        </v-card-title>
        <v-card-text class="text-h6 text-sm-h4 text-md-h3 text-lg-h1 text-center white--text font-weight-black"
                     :style="fontSizeWidth">
          Encuentra las mejores<br />
          ofertas de empleo
        </v-card-text>
      </template>
      <template #body-content>
        <div class="px-10" ref="sectionGrid">
          <v-row class="justify-end mt-10">
            <v-col cols="12" sm="4">
              <v-autocomplete :items="instituciones" item-value="id" item-text="nombre" v-model="institucion"
                              color="textCyan"
                              @change="searchInstitucion" class="inputStyle" label="Institución" outlined
                              rounded></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field outlined rounded v-model="search" color="textCyan"
                            append-icon="mdi-magnify" @keyup="searchEmpleos" class="inputStyle"
                            label="Nombre de la plaza"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center" v-if="listaEmpleos?.length <= 0">
              <span class="d-flex align-center text-h6 text-center"
                    style="height: 200px">No hay empleos disponibles</span>
          </v-row>
          <v-container v-else class="px-md-16">
            <v-row>
              <v-col cols="12" sm="6" md="4" v-for="empleo in listaEmpleos" :key="empleo.id"
                     class="d-flex justify-center">
                <v-card width="435" height="536" class="rounded-xl card-shadow--style"
                >
                  <v-card-title class="pa-0">
                    <div style="width: 100%; height: 200px" class="d-flex align-center pa-5">
                      <img
                          :src="urlImgCard(empleo.institucion.logo)"
                          width="100%"
                          height="100%"
                          class="rounded-t-xl"
                          alt="imagen"
                      />
                    </div>
                  </v-card-title>
                  <v-card-text class="px-8 pt-8 pb-0">
                    <p class="textCyan--text text-body-1 text-truncate--style-title font-weight-black">{{ empleo.nombre }}</p>
                    <p class="text-sm-subtitle-1 text-truncate--style" v-html="empleo.descripcion_del_puesto"></p>
                    <p>{{ `Fecha de publicación: ${moment(empleo.created_at).format('DD [de] MMMM YYYY')}` }}</p>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center px-5 pt-0 pb-5">
                    <v-btn class="white--text" color="textCyan" rounded width="100" @click="redirectTo(empleo.id)"
                           :small="$vuetify.breakpoint.smAndDown">Ver más
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-row class="justify-center ma-10">
            <v-pagination
                v-model="pagination"
                :length="totalPages"
                @input="getPagination"
                :total-visible="9"
                color="textCyan"
            ></v-pagination>
          </v-row>
        </div>
      </template>
    </app-layout>


  </v-container>


</template>
<script>
import AppLayout from "@/components/AppLayoutComponent.vue";

export default {
  components: {AppLayout},
  data() {
    return {
      listaEmpleos: [],
      search: "",
      timeSearch: null,
      pagination: 1,
      per_page: null,
      total_rows: null,
      instituciones: [],
      institucion: null
    }
  },
  methods: {
    urlImgCard(imgUrl) {
      return `${process.env.VUE_APP_API_URL}/${imgUrl}`
    },
    async searchEmpleos() {
      clearTimeout(this.timeSearch)
      this.timeSearch = setTimeout(async () => {
        this.pagination = 1
        await this.getEmpleos()
      }, 300)
    },
    async searchInstitucion() {
      this.pagination = 1
      await this.getEmpleos()
    },
    async getPagination() {
      await this.getEmpleos()
      this.$refs.sectionGrid.scrollIntoView({behavior: 'smooth'});
    },
    async getEmpleos() {
      const data = {
        search: this.search,
        id_institucion: this.institucion,
        pagina: this.pagination,
        cantidad_por_pagina: 9
      }
      const response = await this.services.MercadoLaboral.getEmpleos(data)
      this.pagination = Number(response.headers.page)
      this.per_page = Number(response.headers.per_page)
      this.total_rows = Number(response.headers.total_rows)
      this.listaEmpleos = response.data.data
    },
    async getInstitucion() {
      const {data} = await this.services.MercadoLaboral.getInstituciones()
      this.instituciones = data.data
    },
    redirectTo(id) {
      this.$router.push(
          {
            name: 'mercado-laboral-detalles',
            params: {id: id},
            query: {page: this.pagination}
          }
      )
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total_rows / this.per_page) || 0;
    },
    fontSizeWidth(){
      return {
        fontSize: this.$vuetify.breakpoint.width < 190 ? '11px !important'
            : this.$vuetify.breakpoint.width < 242 ? '15px !important' : ''
      }
    }

  },
  async created() {
    if (this.$route.query.page) {
      this.pagination = Number(this.$route.query.page)
      this.$nextTick(() => {
        this.$refs.sectionGrid.scrollIntoView({behavior: 'smooth'});
      })
    } else {
      window.scrollTo({top: 0, left: 0});
    }
    await this.getEmpleos()
    await this.getInstitucion()


  }
}
</script>
<style lang="scss" scoped>
.card-shadow--style {

  box-shadow: 0px 0px 1px -2px rgba(0, 0, 0, 0.2), 9px 8px 2px 0px rgba(0, 0, 0, 0.14), 14px 14px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
> .v-input__control
> .v-input__slot
fieldset {
  color: #30A4F1 !important;
}

.inputStyle ::v-deep i::before {
  color: #30A4F1 !important;
}

.inputStyle ::v-deep label {
  color: #30A4F1 !important;
}

::v-deep .v-pagination__item {
  color: #30A4F1 !important;
}

::v-deep .v-pagination__item--active {
  color: white !important;
}

::v-deep .v-pagination__navigation i {
  color: #30A4F1 !important;
}

.text-truncate--style {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 81px;

}

.text-truncate--style-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 51px;
}
</style>
